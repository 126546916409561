import { lazy } from "react";

export const Route = new Map([
  [
    "Homepage",
    {
      Path: "/",
      Component: lazy(() => import("component/Landing")),
      Exact: true,
    },
  ],
  [
    "Dashboard",
    {
      Path: "/home",
      Component: lazy(() => import("component/Homepage")),
      Exact: true,
    },
  ],
  [
    "Login",
    {
      Path: "/login",
      Component: lazy(() => import("component/Login")),
      Exact: true,
    },
  ],
  [
    "Register",
    {
      Path: "/register",
      Component: lazy(() => import("component/Signup")),
      Exact: true,
    },
  ],
  [
    "Profile",
    {
      Path: "/profile",
      Component: lazy(() => import("component/Profile")),
      Exact: true,
    },
  ],
  [
    "OAuthTwitch",
    {
      Path: "/oauth/twitch",
      Component: lazy(() => import("component/TwitchAuth")),
    },
  ],
  [
    "Notifications",
    {
      Path: "/notifications",
      Component: lazy(() => import("component/Notifications")),
    },
  ],
  [
    "LegalTerms",
    {
      Path: "/terms",
      Component: lazy(() => import("component/LegalTerms")),
    },
  ],
  [
    "LegalPrivacy",
    {
      Path: "/privacy",
      Component: lazy(() => import("component/LegalPrivacy")),
    },
  ],
  [
    "Blocked",
    {
      Path: "/blocked",
      Component: lazy(() => import("component/UserBlocked")),
    },
  ],
  [
    "404",
    {
      Path: "/404",
      Component: lazy(() => import("component/404")),
    },
  ],
  [
    "About",
    {
      Path: "/about",
      Component: lazy(() => import("component/AboutPage")),
    },
  ],
  [
    "PasswordReset",
    {
      Path: "/passwordreset",
      Component: lazy(() => import("component/ProcessPasswordReset")),
    },
  ],
  [
    "Contact",
    {
      Path: "/contact",
      Component: lazy(() => import("component/ContactPage")),
    },
  ],
  [
    "ConfirmEmail",
    {
      Path: "/confirm",
      Component: lazy(() => import("component/ConfirmEmail")),
    },
  ],
  [
    "UserProfile",
    {
      Path: "/:user",
      Component: lazy(() => import("component/UserProfile")),
      Exact: true,
    },
  ],
]);

export const LoggedOutRoutes = [
  Route.get("Homepage"),
  Route.get("Login"),
  Route.get("Register"),
  Route.get("LegalTerms"),
  Route.get("LegalPrivacy"),
  Route.get("Contact"),
  Route.get("About"),
  Route.get("PasswordReset"),
  Route.get("ConfirmEmail"),
  Route.get("404"),
  Route.get("UserProfile"),
];

export const LoggedInRoutes = [
  Route.get("Dashboard"),
  Route.get("Login"),
  Route.get("Register"),
  Route.get("LegalTerms"),
  Route.get("LegalPrivacy"),
  Route.get("Contact"),
  Route.get("About"),
  Route.get("Blocked"),
  Route.get("ConfirmEmail"),
  Route.get("404"),
  Route.get("UserProfile"),
];

export const Color = {
  White: "#fff",
  LighterGrey: "#eee",
  LightGrey: "#ccc",
  MediumGrey: "#888",
  DarkGrey: "#333",
  Green: "#b4d273",
  Red: "#b05279",
  LightRed: "#f0a7c5",
  Orange: "#e87d3e",
  Blue: "#6c99bb",
} as const;

export const Font = {
  Body: "Manrope, -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;",
  Heading:
    "Manrope, -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;",
  Display: "Staatliches, cursive;",
} as const;

export const Build = {
  CDN: "https://cdn.userid.gg",
};

export const SocialMediaLinks = {
  Twitter: "https://twitter.com/useridgg",
  Discord: "https://discord.gg/D29fxAxba6",
  Mail: "mailto:hey@userid.gg",
};

export const DefaultDisplayPicture = `${Build.CDN}/defaultprofile.jpeg`;
